import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import { connect } from "react-redux";
import GetAllTasksServices from '../../../../../../../Service/OPSServices/SWInternalServices/GetAllTasksServices';
import ViewTaskHistory from './SubComponentsSwTeamTask/ViewTaskHistory';
import CreateNewTask from './SubComponentsSwTeamTask/CreateNewTask';
import AddHistoryService from '../../../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';
import TaskCreatedByMe from './SubComponentsSwTeamTask/TaskCreatedByMe';
import TaskAssingedToMe from './SubComponentsSwTeamTask/TaskAssingedToMe';

function SWTeamTask(props) {
  const [taskList, setTaskList] = useState([])
  const [mainTaskList, setMainTaskList] = useState([])
  const [assignedToTaskList, setAssignedToTaskList] = useState([])
  const [createdByTaskList, setCreatedByTaskList] = useState([])
  const [openTaskTitle, setOpenTaskTitle] = useState(false)
  const [taskName, setTaskName] = useState();
  const [viewTaskPopup, setViewTaskPopup] = useState(false)
  const [taskData, setTaskData] = useState()
  const [createTaskPopup, setCreateTaskPopup] = useState(false)
  const [teamMemberList, setTeamMemberList] = useState('')
  const [taskStatus, setTaskStatus] = useState()
  const [statusFilter, setStatusFilter] = useState('All')
  const [searchInput, setSearchInput] = useState("");
  const [satusLoading, setSatusLoading] = useState(false)
  const [assignedLoading, setAssingedLoading] = useState(false)
  const [createdByMePage, setCreatedByMePage] = useState(false)
  const [assingedToMePage, setAssingedToMePage] = useState(false)
  const [seletctedCurrentPage,setSeletctedCurrentPage] = useState()

  useEffect(() => {
    GetAllTasksServices(props.token).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setTaskList(responce.tasklist)
        setMainTaskList(responce.tasklist)
        // setAssignedToTaskList(filteredAssignedToTasks)
        // setCreatedByTaskList(filteredCreatedByIdTasks)
        setTeamMemberList(responce.teamMembers)
        setTaskStatus(responce.statusList)
        console.log(taskList)
        console.log(taskList.createdById)
        handleCreatedByTaskList(responce.tasklist, responce.loggedUserId)
        handleAssignedToTaskList(responce.tasklist, responce.loggedUserId)
      }
    })

  }, [])

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }
  const handleCreatedByTaskList = (tasks, loggedUserId) => {
    setCreatedByTaskList(tasks && tasks.filter(task => task.createdById === loggedUserId))
  };
  const handleAssignedToTaskList = (tasks, loggedUserId) => {
    setAssignedToTaskList(tasks && tasks.filter(task => task.assignedTo === loggedUserId));
  };
  const filteredTaskList = taskList && taskList.filter((task) => {
    const matchesStatus = statusFilter === "All" || task.status === statusFilter;
    const matchesSearch = task.taskTitle.toLowerCase().includes(searchInput.toLowerCase());
    const assingnedTo = task.assignedName.toLowerCase().includes(searchInput.toLowerCase());
    const status = task.status.toLowerCase().includes(searchInput.toLowerCase());
    return matchesStatus && (matchesSearch || status || assingnedTo);
  });

  const handleNewTask = () => {
    setCreateTaskPopup(true)
  }
  const handleViewTaskTitle = (task) => {
    setTaskName(task)
    setOpenTaskTitle(true)
  }
  const handleVivewTask = (taskData) => {
    setViewTaskPopup(true)
    setTaskData(taskData)
  }
  const handleCloseCreateTaskPopup = (tasklist) => {
    setTaskList(tasklist);
    setCreateTaskPopup(false)
    setViewTaskPopup(false)
  }
  const handleSubmitHistory = (e, task, flag) => {

    const formData = new FormData();
    if (flag == 'assignedTo') {
      formData.append("assignedTo", e.target.value);
      formData.append("note", 'assignedTo');
    }
    else {
      formData.append("assignedTo", task.assignedTo);
    }
    if (flag == 'status') {
      formData.append("status", e.target.value);
      formData.append("note", 'status');
    }
    else {
      formData.append("status", task.status);
    }
    formData.append("taskId", task.SWTeamTaskId);
    formData.append("flag", 1);

    console.log(formData);
    AddHistoryService(props.token, formData).then((res) => {
      if (res) {
        console.log(formData)
        console.log(res)
        setTaskList(res.tasklist)
      }
      setSatusLoading()
      setAssingedLoading()
    })
  }
  const handleCreatedByMe = (value) => {
    setCreatedByMePage(true)
    setSeletctedCurrentPage(value)

  }
  const handleAssingedToMe = (value) => {
    setAssingedToMePage(true)
    setSeletctedCurrentPage(value)
  }
  const handleBackFunction = (value) => {
    setCreatedByMePage(false)
    setAssingedToMePage(false)
    setSeletctedCurrentPage(value)

  }
  return (
    <>
      <Card className="card-min-height-workflows mt-3 p-3 card-of-problemarea">
        <Row>
        <Col md={12} className='mt-2'>
        <div className='d-flex'>
        {
          createdByMePage || assingedToMePage ?
          <h6
          className=' not-seletcted-tasks mr-3'
          onClick={() => handleBackFunction()}>
            All Task
         
        </h6>:''
        }
          <h6
            className={seletctedCurrentPage === 2?'selected-my-tasks':'not-seletcted-tasks'}
            onClick={() => handleCreatedByMe(2)}>
            Created By Me
          </h6>
          <h6
            className={` ml-3 ${seletctedCurrentPage === 1?'selected-my-tasks ':'not-seletcted-tasks'}`}
            onClick={() => handleAssingedToMe(1)}>
            Assigned To Me
          </h6>

          <Button
            className='btn-background-color ml-auto'
            onClick={() => handleNewTask(true)}>
            New Task
          </Button>
          </div>
        </Col>

        {createdByMePage ?
          <TaskCreatedByMe
            teamMemberList={teamMemberList}
            taskList={createdByTaskList}
            taskStatus={taskStatus}
            setTaskList={setTaskList}
            handleBackFunction={handleBackFunction}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          /> :
          assingedToMePage ?
            <TaskAssingedToMe
              teamMemberList={teamMemberList}
              setTaskList={setTaskList}
              taskList={assignedToTaskList}
              taskStatus={taskStatus}
              handleBackFunction={handleBackFunction}
              handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
            /> :
            <Row className='p-3 '>
              <Col md={12}>
                <Input type='text'
                  className='w-25 '
                  placeholder='Search Text'
                  onChange={(e) => handleSearchInputChange(e)}
                />
                <label className='mr-3 mt-2'>
                  <input type='radio'
                    name='status'
                    checked={statusFilter === "All"}
                    value={statusFilter}
                    //  checked={status.status}
                    onChange={() => setStatusFilter('All')} />
                  &nbsp;
                  <b>All</b>
                </label>
                {taskStatus && taskStatus.map((status) => (
                  <label className='mr-3'>
                    <input type='radio'
                      name='status'
                      checked={statusFilter === status.status}
                      value={status.status}
                      //  checked={status.status}
                      onChange={() => setStatusFilter(status.status)} />
                    &nbsp;
                    <b>{status.status}</b>
                  </label>
                ))}

              </Col>
              <Col md={12} className="">
                <table className="table custom-scroll-table">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Title</th>
                      <th scope="col">Status</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Planned Start Date</th>
                      <th scope="col">Planned End Date</th>
                      <th scope="col">Created By</th>
                      <th scope="col">Completed At</th>
                    </tr>
                  </thead>
                  <tbody className="tasklist-scrolle">
                    {filteredTaskList &&
                      filteredTaskList.map((task) => (
                        <tr key={task.SWTeamTaskId} style={{ backgroundColor: task.rowColor ? task.rowColor : '' }}>
                          
                          <td className="cursor-pointer">
                          <div className="d-flex justify-content-center">
                          <p className="mr-2 mb-0" onClick={() => handleVivewTask(task)}>
                                {task.SWTeamTaskId}
                              </p>
                              </div>
                          </td>
                          <td className="cursor-pointer">
                            <div className="d-flex">
                             
                              <p
                                className="m-0 comment-paragraph"
                                onClick={() => handleViewTaskTitle(task.taskTitle)}
                              >
                                {task.taskTitle}
                              </p>
                            </div>
                          </td>
                          <td>
                            {satusLoading === task.SWTeamTaskId ? (
                              <p className="mb-0" style={{ color: "#81e94b" }}>
                                Updating..
                              </p>
                            ) : (
                              <select
                                name="status"
                                id="status"
                                className="form-control w-75"
                                onChange={(e) => {
                                  handleSubmitHistory(e, task, "status");
                                  setSatusLoading(task.SWTeamTaskId);
                                }}
                              >
                                <option value="">-- Select status --</option>
                                {taskStatus &&
                                  taskStatus.map((status) => (
                                    <option
                                      value={status.status}
                                      selected={status.status === task.status}
                                    >
                                      {status.status}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </td>
                          <td>
                            {assignedLoading === task.SWTeamTaskId ? (
                              <p className="mb-0" style={{ color: "#81e94b" }}>
                                Updating..
                              </p>
                            ) : (
                              <select
                                className="form-control w-75"
                                name="assignedto"
                                id="assignedto"
                                onChange={(e) => {
                                  handleSubmitHistory(e, task, "assignedTo");
                                  setAssingedLoading(task.SWTeamTaskId);
                                }}
                              >
                                <option value="">-- Assigned to --</option>
                                {teamMemberList &&
                                  teamMemberList.map((member) => (
                                    <option
                                      key={member.employeeId}
                                      value={member.employeeId}
                                      selected={member.employeeId === task.assignedTo}
                                    >
                                      {member.employeeName}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </td>
                          <td>{task.plannedStartDate}</td>
                          <td>{task.plannedStartDate}</td>
                          <td>{task.createdBy}</td>
                          <td>{task.completedAt}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            </Row>
        }
        </Row>
      </Card>
      
      <CustomModal
        header={"Create task"}
        isOpen={openTaskTitle}
        toggle={() => setOpenTaskTitle(!openTaskTitle)}
        size={"md"}
        className='other-bgColor'
      >
        <Card className='card-of-problemarea p-2'>
          <Col md={12} >
            <p>{taskName}</p>
          </Col>

        </Card>
      </CustomModal>
      <CustomModal
        header={<>
          <h6>View Task / <b>Task Id :</b>&nbsp;{taskData && taskData.SWTeamTaskId}</h6>
        </>}
        isOpen={viewTaskPopup}
        toggle={() => setViewTaskPopup(!viewTaskPopup)}
        size={"lg"}
        className='other-bgColor'
      >
        <>
          <ViewTaskHistory
            teamMemberList={teamMemberList}
            statusList={taskStatus}
            taskList={taskList}
            taskName={taskName}
            viewTaskData={taskData}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </>

      </CustomModal>
      <CustomModal
        header={"Task Title"}
        isOpen={createTaskPopup}
        toggle={() => setCreateTaskPopup(!createTaskPopup)}
        size={"lg"}
        className='other-bgColor'
      >
        <Card className='card-of-problemarea'>
          <CreateNewTask
            teamMemberList={teamMemberList}
            taskList={taskList}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </Card>
      </CustomModal>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(SWTeamTask);