import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import ReactQuill from 'react-quill';
import { Editor } from '../../../../../../../../Utils/Constants';
import AddNewTaskServices from '../../../../../../../../Service/OPSServices/SWInternalServices/AddNewTaskServices';
import DateRangePicker from '../../../../../../../Components/Input/DateRangePicker';
import { connect } from "react-redux";

function CreateNewTask(props) {
  const [newTask, setNewTask] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([])
  const [text, setText] = useState('')
  const [teamMemberList, setTeamMemberList] = useState('')
  const [taskStatus, setTaskStatus] = useState('');
  const [member, setMember] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [dateRange, setDateRange] = useState({
      startDate: null,
      endDate: null,
  });

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
};

const handleSubmitSwTask = () => {
    setSaveLoading(true)
    const formData = new FormData();
    formData.append("taskTitle", newTask);
    formData.append("status", 'new');
    formData.append("Comments", text);
    formData.append("startDate", dateRange.startDate);
    formData.append("endDate", dateRange.endDate);
    formData.append("assignedTo", member);
    if (selectedFiles.length > 0) 
    {
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("taskAttachmentFile", selectedFiles[i]);
        }
    }
    else
    {
        formData.append("taskAttachmentFile",[]);
    }
    AddNewTaskServices(props.token, formData).then((res) => {
        if (res) {
            console.log(res)
            setText('')
            setDateRange({
                startDate: null,
                endDate: null,
            });
            setSelectedFiles([])
            setTaskStatus('')
            setNewTask('')
            setMember('')
            props.handleCloseCreateTaskPopup(res.tasklist)
        }
        setSaveLoading(false)
    })
  
}

const handleChangeStatus = (e) => {
    setTaskStatus(e.target.value)
}
const handleQuillChange = (value) => {
    setText(value);
};
const handleAssignedToChange = (e) => {
    setMember(e.target.value)
}
const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
};

  return (
    <>
     <Card className='card-of-problemarea p-4'>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Label> <b>Task Title :<span  style={{ color: "red" }}
                                > * </span></b>  </Label>
                                <Input
                                    type='text'
                                    className='w-100'
                                    placeholder='Enter Text'
                                    onChange={(e) => setNewTask(e.target.value)}
                                />
                            </Col>
                            <Col  md={6}>
                                <Label><b> Assigned To :</b></Label>
                                <select
                                    id='assigned To'

                                    onChange={(e) => handleAssignedToChange(e)

                                    }
                                    className='form-control w-100'>
                                    <option id='' value=''>--Assigned To--</option>
                                    {props.teamMemberList && props.teamMemberList.map((member) => (
                                        <option
                                            key={member.employeeId}
                                            value={member.employeeId}
                                        // selected={member.employeeId}
                                        >{member.employeeName}</option>
                                    ))}
                                </select>
                            </Col>
                            
                            <Col md={12} className='mt-3'>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                            </Col>
                           

                            <Col className='mt-2' md={6}>

                                <p for="Attachment">
                                    <b>Helpful Screenshots Or Images : </b>
                                </p>
                                <label
                                    htmlFor="fileInput"
                                    className="custom-file-input-label text-center"
                                >
                                    <i className="fas fa-upload"></i>
                                    <br /> Choose File

                                    <input
                                        type="file"
                                        id="fileInput"
                                        name="ticketAttachment"
                                        className="custom-file-input"
                                        onChange={handleFileChange}
                                    />
                                </label>
                                </Col>
                                <Col md={6} className='mt-2'>
                                <span style={{ fontSize: "13px" }}><b>Attached File List :</b></span>
                                <ul style={{ color: "green", fontSize: "13px" }}>
                                    {selectedFiles
                                        ? selectedFiles.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <button
                                                    className="btn"
                                                    onClick={() => handleRemoveFile(file)}
                                                >
                                                    <FontAwesomeIcon
                                                        color="red"
                                                        size="15px"
                                                        icon={faWindowClose}
                                                    />
                                                </button>
                                            </li>
                                        ))
                                        : ""}
                                </ul>

                            </Col>
                            <Col md={12} className='mt-2 '>
                            <Label><b>Add Note For Tasks <span style={{ color: 'red' }}> *</span></b></Label>
                                <ReactQuill
                                    className=""
                                    theme={'snow'}
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    value={text}
                                    onChange={handleQuillChange}
                                    bounds={'.quill-prod'}
                                    placeholder='Note' />
                            </Col>
                            <Col md={12} className='d-flex justify-content-center mt-2'>
                                <Button
                                className='btn-background-color '
                                disabled = {!(newTask && text) || saveLoading}
                                    onClick={() => handleSubmitSwTask()}>
                                    {saveLoading ? 'Saving..' :'Save'}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Card>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(CreateNewTask);
